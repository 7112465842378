import { useState } from 'react';
import { useParams } from 'react-router-dom';
import SharedReviewService from '../../services/sharedreviews/service';
import {
    onWebAppDownloadImages,
    onWebAppDownloadImagesComplete,
    onWebAppDownloadSharedReview,
    onWebAppDownloadSharedReviewCompleted,
} from '../../tracking/trackers';
import ReceiptService from '../../services/receipts/service';
import MiscService from '../../services/misc';
import ExportService from '../../services/export/service';
import { useDownloadUrl } from '../../providers/DownloadUrlProvider';
import useLocalStoragePrefs from '../../components/FolderSpreadsheet/useLocalStoragePrefs';
import { COLUMNS } from '../../components/FolderSpreadsheet/constants';
import { useSharedReview } from './context';

const useHandleDownload = (
    sourceIsMobileApp?: boolean,
    onClick?: () => void
) => {
    const { id: sharedReviewId } = useParams();
    const { setUrl } = useDownloadUrl();
    const { getData: getLocalStoragePrefs } =
        useLocalStoragePrefs(sharedReviewId);

    const [loading, setLoading] = useState<boolean>(false);
    const { receipts } = useSharedReview();

    const source = sourceIsMobileApp ? 'mobile' : 'desktop';

    const downloadImagesFromDesktopApp = async (
        fileType: 'pdf' | 'zip',
        folderIds: string[]
    ) => {
        onClick && onClick();
        const useExportService = true;
        onWebAppDownloadImages({
            file_type: fileType,
            source: 'shared-review',
        });
        const start = performance.now();
        try {
            setLoading(true);
            if (fileType === 'zip' && useExportService) {
                const imageIds = (await ReceiptService.loadImageIds()).data;
                const blob = await ExportService.downloadZip(
                    undefined,
                    imageIds
                );
                const url = URL.createObjectURL(blob);
                window.open(url);
                setUrl(url);
            } else {
                const fileId = await ReceiptService.downloadImages(
                    fileType,
                    folderIds
                );
                const url = await MiscService.getFileURL(fileId);
                setUrl(url);
            }
            onWebAppDownloadImagesComplete({
                file_type: fileType,
                status: 'success',
                timeToComplete: performance.now() - start,
            });
        } catch (e) {
            console.log(e);
            onWebAppDownloadImagesComplete({
                file_type: fileType,
                status: 'error',
                timeToComplete: performance.now() - start,
            });
        } finally {
            setLoading(false);
        }
    };

    const downloadFromSharedReview = async (
        fileType: string,
        tab: string,
        includeLineItems?: boolean,
        tag_ids?: string[]
    ) => {
        if (!sharedReviewId) return;
        onClick && onClick();
        const useExportService = true;
        onWebAppDownloadSharedReview({
            shared_review_id: sharedReviewId,
            tab,
            file_type: fileType,
            source,
            use_export_service: useExportService,
        });
        const start = performance.now();
        try {
            setLoading(true);
            if (fileType === 'zip' && useExportService) {
                const imagesData = Array.from(receipts.values())
                    .map((r) => ({
                        ...r,
                        image_ids: r.image_ids || [],
                    }))
                    .reduce(
                        (a: { image_id: string; file_name: string }[], b) =>
                            a.concat(
                                b.image_ids.map((r2) => ({
                                    image_id: r2,
                                    file_name:
                                        b.date?.toString().split('T')[0] +
                                        '_' +
                                        b.merchant,
                                }))
                            ),
                        []
                    );
                const imageIds = imagesData.map((i) => i.image_id);
                const filesName = imagesData.map((i) => i.file_name);
                const blob = await ExportService.downloadZip(
                    sharedReviewId,
                    imageIds,
                    filesName
                );
                const url = URL.createObjectURL(blob);
                window.open(url);
                setUrl(url);
            } else {
                const columns = (getLocalStoragePrefs()?.columns || []).filter(
                    (c) => !!c.name
                );
                // Must include "total" column even if it's hidden
                if (!columns.find((c) => c.key === 'total')) {
                    const totalCol = COLUMNS.find((c) => c.key === 'total');
                    totalCol && columns.push(totalCol);
                }
                const fileId = await SharedReviewService.getDownload(
                    sharedReviewId,
                    fileType,
                    includeLineItems,
                    tag_ids,
                    columns
                );
                const url = await MiscService.getFileURL(fileId);
                setUrl(url);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            onWebAppDownloadSharedReviewCompleted({
                shared_review_id: sharedReviewId,
                tab,
                timeToComplete: performance.now() - start,
                file_type: fileType,
                source,
                use_export_service: useExportService,
            });
        }
    };

    return { downloadFromSharedReview, downloadImagesFromDesktopApp, loading };
};

export default useHandleDownload;
